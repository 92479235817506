<template>
	<modal name="booking-modal" :adaptive="true" @closed="closeModal" @before-close="validate" :width=1150 height="auto" :scrollable="true">
		<div class="clearfix float-right modal-header border-0">
			<button class="close" @click="$modal.hide('booking-modal');">
				<span aria-hidden="true">×</span>
			</button>
		</div>
		<div class="m-4">
			<save-template v-if="saveTemplate" @templateSaved="saveNewTemplate" @closed="onTemplateClosed"></save-template>
			<div v-if="!this.user.user.member">
				<div class="clearfix">
					<h4 class="float-left">{{ $t('labels.no-member-linked-to-user') }}</h4>
				</div>
			</div>
			<div v-if="this.user.user.member">
				<div class="clearfix">
					<h4 class="float-left">{{ headline }}</h4>
					<button v-if="!template.id" type="submit" class="btn btn-success float-right mt-3 mr-0" @click="book">
						{{ $t('labels.save') }}
					</button>

					<button v-if="!template.id" type="submit" class="btn btn-secondary float-right mt-3 mr-3" @click="cancel">
						{{ $t('labels.cancel') }}
					</button>
					<div class="subheader-right">
					</div>

				</div>
				<div class="bt-1 bb-1 px-3">
					<div class="row">
						<div class="col-12 pt-3">
							<div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label>{{ $t('labels.type') }}</label>
													<v-select
													:options="bookables"
													transition="none"
													:filterable="false"
													v-model="booking.booking_type"
													:clearable="false"
													:label="$label()"
												><span slot="no-options">{{ $t('labels.no-values') }}</span></v-select>

											</div>
										</div> 
										<div class="col-12 col-lg-6">
											<div class="form-group" v-if="booking.booking_type && (booking.booking_type.bookable || booking.booking_type.bookables)">
												<label>{{ $to(booking.booking_type,'label_short') }}</label>
												<v-select
													:options="bookables.find((bookable) => bookable.id == booking.booking_type.id).bookables"
													transition="none"
													:filterable="false"
													v-model="booking.booking_type.bookable"
													:clearable="false"
													label="label"
												><span slot="no-options">{{ $t('labels.no-values') }}</span></v-select> 
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col-6">
											<div class="form-group">
												<label>{{ $t('labels.category') }}</label>
													<v-select
													:options="bookingCategories"
													transition="none"
													:filterable="false"
													v-model="booking.booking_category"
													:clearable="true"
													:label="$label()"
												><span slot="no-options">{{ $t('labels.no-values') }}</span></v-select>
											</div>
										</div> 
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label for="subject">{{ $t('labels.heading') }}</label>
										<input type="text" v-model="booking.label" class="form-control" id="label" placeholder="" />
									</div>
								</div>
								<div class="col-12 col-lg-6">
									<v-guard :permissions="['calendar-set-capacity-limit']">
										<div class="form-group" v-if="canShowCapacity()">
											<label for="capacity">{{ $t('labels.capacity') }}</label><info-box :field="'Kalender - Kapacitet i formulär'" />
											<input type="number" v-model="booking.capacity" class="form-control" id="capacity" placeholder="" />
										</div>
									</v-guard>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<v-guard :permissions="['calendar-set-bookable']">
										<div class="form-group">
											<label for="isBookable">{{ $t('labels.bookable') }}</label> <input type="checkbox" id="isBookable"  v-model="is_bookable" >
										</div>
									</v-guard>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label for="subject">{{ $t('labels.begins') }}</label>
										<input type="datetime-local" v-model="booking.starts_at" class="form-control" id="startsAt" placeholder="" />
									</div>
								</div>

								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label for="subject">{{ $t('labels.ends') }}</label>
										<input type="datetime-local" v-model="booking.ends_at" class="form-control" id="endsAt" placeholder="" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="description">{{ $t('labels.comment') }}</label>
										<ckeditor :editor="editor" v-model="booking.description" :config="editorConfig" class="form-control" id="description" placeholder=""></ckeditor>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="form-group"></div>
								</div>
							</div>
						</div>
					
						
					</div>
					
				</div>
				<div class="px-3 pt-3">
					<div class="row">
						<div class="col-12">
							<div class="form-group"><h6 class="sublabel">{{ $t('labels.template') }} <info-box :field="'Kalender - Mall i formulär'" /></h6></div>
							<button v-if="template.id" @click="updateTemplate()" class="btn btn-sm btn-light mr-3"><i
									class="fas fa-save"></i> {{ $t('labels.save-template') }}
							</button>
							<button @click="openSaveTemplate()" class="btn btn-sm btn-light mr-3"><i
									class="fas fa-save"></i> {{ $t('labels.save-template-new') }}
							</button>
							<button v-if="template.id" @click="deleteTemplate()" class="btn btn-sm btn-danger mr-3"><i
									class="fas fa-trash"></i> {{ $t('labels.delete-template') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>
<script>
import BojAPI from '@/api/boj';
import moment from 'moment';
import InfoBox from '@/components/InfoBox';
import SaveTemplate from '@/components/calendar/SaveTemplate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
	props: {
		booking: Object,
		member: Object,
		bookingCategories: Array,
		bookables: Array,
		isBookable: [Boolean, Number],
		template: Object,
	},
	components: {
		InfoBox,
		SaveTemplate,
	},
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {
				toolbar: ['bold'],
				link: {
					addTargetToExternalLinks: true,
					defaultProtocol: 'https://'
				}
			},
			bookingType: {},
			bookable: {},
			bookable_inner: {},
			moment: moment,
			is_bookable: this.isBookable,
			saveTemplate: false,
		};
	},
	created() {},
	mounted() {
		this.$modal.show('booking-modal');
	},
	computed: {
		user() {
			return this.$store.getters.user;
		},
		headline() {
		
			if(this.template.id) {
				return this.$t('labels.change-template')
			} else if (this.booking.id) {
				return this.$t('labels.change-event');
			} else {
				return this.$t('labels.new-event');
			}
		},
		startsAt: {
			get() {
				return moment(this.booking.start).format('YYYY-MM-DDThh:mm');
			},

		},
		endsAt: {
			get() {
				return moment(this.booking.end).format('YYYY-MM-DDThh:mm');
			},
		},
	},
	methods: {
		openSaveTemplate() {
			if(!this.validateBooking()) {
				return false;
			}
			this.saveTemplate = true;
		},
		deleteTemplate() {
			BojAPI.deleteBookingTemplate(this.template.id)
				.then((response) => {
					this.showConfirmation(this.$t('labels.deleted'));
					this.$emit('bookingTemplateDeleted', this.template.id);
					this.closeModal();
				})
				.catch((error) => {});
		},
		onTemplateClosed() {
			this.saveTemplate = false;
		},
		saveNewTemplate(label) {
			BojAPI.updateBookingTemplate({label: label, body: {booking: this.booking}})
				.then((response) => {
					this.showConfirmation(this.$t('labels.saved'));
					this.template.id = response.data.id;
					this.template.label = response.data.label;
					this.$emit('bookingTemplateSaved', response.data);
					this.closeModal();
				})
				.catch((error) => {});
		},
		updateTemplate() {
			this.booking.is_bookable = this.is_bookable;
			BojAPI.updateBookingTemplate({id: this.template.id, label: this.template.label, body: {booking: this.booking}})
				.then((response) => {
					this.showConfirmation(this.$t('labels.saved'));
					this.template.id = response.data.id;
					this.template.label = response.data.label;
					this.$emit('bookingTemplateUpdated', response.data);
					this.closeModal();
				})
				.catch((error) => {});
		},
		canShowCapacity() {
			return this.is_bookable ? true : false;
		},
		showError(value) {
			this.$notify({
				group: 'foo',
				text: value,
				type: 'warning',
				duration: 3000,
			});
		},

		showConfirmation(value) {
			this.$notify({
				group: 'foo',
				text: value,
				type: 'success',
				duration: 3000,
			});
		},
		closeModal() {
			this.$emit('closed');
		},
		cancel() {
			this.$modal.hide('booking-modal');
		},
		validate(event) {
			var retVal = confirm(this.$t('labels.confirm-close'));
               if( retVal == true ) {
					this.$emit('aborted');
                  return true;
               } else {
                  event.stop();
               }
		},
		validateBooking() {
			if (!this.booking.booking_type) {
				this.showError(this.$t('labels.booking-type-field-empty-error'))
				return false;
			}

			if (this.booking.booking_type && this.booking.booking_type.tag != 'national' && !this.booking.booking_type?.bookable?.id) {
				this.showError(this.$t('labels.filter-type-field-empty-error'));
				return false;
			}

			if (!this.booking.label || this.booking.label.length === 0 ) {
				this.showError(this.$t('labels.heading-field-empty-error'));
				return false;
			}

			if ((!this.booking.starts_at || this.booking.starts_at.length === 0) || (!this.booking.ends_at || this.booking.ends_at.length == 0)) {
				this.showError(this.$t('labels.time-field-empty-error'))
				return false;
			}

			return true;
		},

		makeBookingPayload() {
			if(!this.validateBooking()) {
				return false;
			}

			return {
				id: this.booking.id,
				tag: this.booking.booking_type.tag,
				bookable_id: this.booking.booking_type?.bookable?.id,
				category_id: this.booking.booking_category?.id,
				is_bookable: this.is_bookable,
				label: this.booking.label,
				description: this.booking.description,
				capacity: this.booking.capacity,
				starts_at: this.booking.starts_at,
				ends_at: this.booking.ends_at,
			};
		},
		book() {
			var payload = this.makeBookingPayload();
			if(!payload) {
				return false;
			}

			BojAPI.createBooking(payload)
				.then((response) => {
					this.showConfirmation(this.booking.id ? this.$t('labels.booking-updated') : this.$t('labels.booking-created'));
					this.$emit('edited', response.data);
					this.closeModal();
				})
				.catch((error) => {});
		},
	},
};
</script>
<style scoped>
/* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */




</style>
