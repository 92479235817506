<template>
	<layout-main>
		<div class="home">
			<modal-edit-booking
				:booking="booking"
				:member="member"
				:bookingCategories="bookingCategories"
				:bookables="bookables"
				:bookable="bookable"
				:bookableType="bookableType"
				:isBookable="booking.is_bookable"
				:template="template"
				@closed="openModalEditBooking = null"
				@aborted="onModalAbort"
				@edited="onEventEdited"
				@bookingTemplateSaved="onBookingTemplateSaved"
				@bookingTemplateDeleted="onBookingTemplateDeleted"
				ref="modalEditBooking"
				v-if="openModalEditBooking"
			></modal-edit-booking>
			<modal-show-booking
				:event="event"
				:member="member"
				@showEditModal="onShowEditModal"
				@closed="
					openModalShowBooking = null;
					$router.push({
						name: 'calendar',
						query: {},
					});
				"
				@changed="getBookings()"
				v-if="openModalShowBooking"
			></modal-show-booking>

			<div class="subheader">
				<div class="subheader-main">
					<div class="subheader-left">
						<h3>
							{{ $t('labels.calendar') }}
						</h3>
						<span class="separator-line"></span>
						<div class="breadcrumbs">
							<router-link to="/calendar" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
							<span class="separator-dot"></span>
							<router-link to="/calendar" class="breadcrumbs-link"> {{ $t('labels.calendar') }}</router-link>
						</div>
					</div>
				
					<div class="subheader-right">
						<button v-if="canCreate()" @click="booking = {};  booking.is_bookable = true; booking.starts_at = setStartTime(); booking.ends_at = moment(booking.starts_at).add(1, 'hour').startOf('hour').format('YYYY-MM-DD HH:mm:ss'); openModalEditBooking = true; template = {id: null,label: null}" class="btn btn-sm btn-light mr-3"><i class="fas fa-envelope"></i> {{ $t('labels.new-event') }}</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
					<div class="widget progress-list">
						<div class="widget-header row ">
							<div class="widget-header-label mt-3 mt-lg-0 order-1 order-lg-1 col-11 col-lg-2">
								
								<h3>
									{{ $t('labels.calendar') }}
								</h3>
							
								<span style="min-width: 140px;" class="mx-3">
									<v-select
											:options="filters"
											transition="none"
											:filterable="false"
											v-model="bookingTypeId"
											:clearable="false"
											:label="$label()"
											@input="bookableId = {}; getBookings();"
										><span slot="no-options">{{ $t('labels.no-values') }}</span></v-select>
								</span>
							</div>
							<div class="widget-header-label mb-3 mb-lg-0 order-2 order-lg-2 col-11 col-lg-3">	
								<h3 class="mt-3 mt-lg-0" v-if="bookingTypeId && (bookingTypeId.bookables)"> {{ $to(bookingTypeId,'label_short') }}</h3>
								<span class="mx-3 mt-3 mt-lg-0" style="min-width: 200px;" v-if="bookingTypeId && (bookingTypeId.bookables)">
									
								<v-select
											
											:options="filters.find((bookable) => bookable.id == bookingTypeId.id).bookables"
											transition="none"
											:filterable="false"
											v-model="bookableId"
											:clearable="false"
											label="label"
											@input="getBookings"
										><span slot="no-options">{{ $t('labels.no-values') }}</span></v-select> 
								</span>
							</div>
							<div class="widget-header-label mb-3 mb-lg-0 booking-templates order-3 order-lg-2 col-12 col-lg-6" >
									<span class="pr-4" v-for="(item, i) in bookingTemplates" :key="i">
										<button class="mx-auto btn btn-sm btn-light" :style="{ backgroundColor: item.body.booking.booking_type.background_color }"
											
											draggable="true"
											@click="onShowEditModal(item.body.booking, {id: item.id, label: item.label})"
											@dragstart="onEventDragStart($event, item)">
											<i :class="item.body.booking.booking_category ? item.body.booking.booking_category.icon : item.body.booking.booking_type.icon" :style="{ color: item.body.booking.booking_category ? item.body.booking.booking_category.icon_color : item.body.booking.booking_type.icon_color }"></i> {{ item.label }}
										</button>
									</span>
							</div>
							<div class="d-flex align-items-center order-2 order-lg-3 col-1 col-lg-1">
								<div class=" ml-auto d-none d-lg-block">
									<a class="cursor-pointer text-uppercase" @click="selectedDate = new Date()">{{ $t('labels.today') }}</a> 
								</div>
							</div>
						</div>
						<div class="widget-body no-padding calender">
							<vue-cal
								ref="vuecal"
								:dblclickToNavigate="false"
								:clickToNavigate="true"
								:selected-date="selectedDate"
								:on-event-click="onEventClick"
								:snap-to-time="15"
								:editable-events="checkEditableEvents()"
								@event-change="onEventChange"
								@event-duration-change="onEventDurationChange"
								@event-drop="onEventDrop"
								@event-create="onEventCreate"
								@event-drag-create="onEventDragCreate"
								:drag-to-create-event="!isMobileDevice()"
								:drag-to-create-threshold="15"
								:locale="locale()"
								:active-view=activeView()
								:disable-views=disableViews()
								events-count-on-year-view
								events-count-on-month-view
								:events="bookings"
								:time-from="6 * 60"
								:time-to="24 * 60"
						
								@view-change="getBookings"
							>
								<template v-slot:event="{ event }"> 
									<div v-b-popover.hover.html.right="hoverParticipantList(event)" :title="hoverTitle(event)" v-if="event.booking && event.booking.booking_type" class="event-content" :style="{ backgroundColor: event.booking.booking_type.background_color }">
										<div class="vuecal__event-title">
										<span class="icon"><i :class="event.booking.booking_category ? event.booking.booking_category.icon : event.booking.booking_type.icon" :style="{ color: event.booking.booking_category ? event.booking.booking_category.icon_color : event.booking.booking_type.icon_color }"></i></span>	{{ event.title }} <span v-if="event.booking.is_bookable" class="badge" :class="
												slotsLeftRemaining(event)
												
											"> {{ slotsLeft(event) }} </span>
										</div>
										<small class="vuecal__event-time">
											<span>{{ event.start.formatTime() }}</span> - <span>{{ event.end.formatTime() }}</span>
										</small>
										<div class="participants" v-if="event.booking.is_bookable && event.booking.participants.length > 0">
											<ul class="text-center list-unstyled" v-for="participant in event.booking.participants" :value="participant.id" :key="participant.id">
												<li class="text-center"><small><b>{{ participant.model.name }}</b></small></li>
											</ul>
										</div>
									</div>
								</template>
							</vue-cal>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import ModalEditBooking from '@/components/calendar/ModalEditBooking';
import ModalShowBooking from '@/components/calendar/ModalShowBooking';
import BojAPI from '@/api/boj';
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/sv.js';
import 'vue-cal/dist/drag-and-drop.js';
import 'vue-cal/dist/vuecal.css';
import _ from 'lodash';
import moment from 'moment';
import { i18n } from '@/plugins/i18n';
import DatePicker from '@/components/DatePicker';

export default {
	name: 'home',
	components: {
		LayoutMain,
		ModalEditBooking,
		ModalShowBooking,
		VueCal,
		DatePicker,
		i18n
	},

	data() {
		return {
			screenWidth: 0,
			moment: moment,
			bookings: [],
			event: null,
			member: {},
			bookables: [],
			bookingTypes: [],
			bookingCategories: [],
			bookable: {},
			bookableType: {},
			bookingTemplates: [],
			booking: {},
			isLoading: false,
			openModalEditBooking: null,
			openModalShowBooking: null,
			selectedDate: null,
			bookingTypeId: {},
			bookableId: {},
			filters: [],
			countYears: [],
		};
	},
	computed: {
		user() {
			return this.$store.getters.user;
		},
		attributes() {
			return [{}];
		},
	},
	mounted() {
		window.addEventListener('resize', this.getDimensions);
		this.getDimensions();
		this.getBookables();
		this.getFilters();
		
		BojAPI.getBookingTemplates().then((response) => {
			this.bookingTemplates = response.data;
		});
		if (this.$store.getters.user.user.member) {
			BojAPI.getMember(this.$store.getters.user.user.member.id).then((response) => {
				this.member = response.data.data;
			});
		}
		
		BojAPI.getBookingTypes().then((response) => {
			this.bookingTypes = response.data;
		});
		BojAPI.getBookingCategories().then((response) => {
			this.bookingCategories = response.data;
		});

		this.getBookings().then(() => {
			if ('id' in this.$route.query) {
				BojAPI.getBooking(this.$route.query.id).then((response) => {
					var booking = response.data;
					if (booking) {
						this.zoomToEvent(booking);
						this.event = {};
						this.event.booking = booking;
						this.openModalShowBooking = true;
					}
				});
			}
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.getDimensions);
	},

	methods: {
		setStartTime() {
			let now = moment();
			let startDate = moment(this.$refs.vuecal.view.startDate);
	
			if(now > startDate) {
				return now.add(1, 'hour').startOf('hour').format('YYYY-MM-DD HH:mm:ss');
			}

			return startDate.set('hour', now.hours()).add(1, 'hour').startOf('hour').format('YYYY-MM-DD HH:mm:ss');
		},
		hoverTitle(event) {
			if(event.booking.is_bookable && event.booking.participants.length > 0) {
				return this.$t('labels.participant');
			}
			return '';
		},
		hoverParticipantList(event) {
			if(event.booking.is_bookable && event.booking.participants.length > 0) {
				return event.booking.participants.map((participant) => participant.model.name).join("<br/>");
			}
			return '';
		},
		locale() {
			return i18n.locale;
		},
		onShowEditModal(booking, template = {label: "", id: null}) {
			this.booking = booking;
			this.bookingType = booking.booking_type;
			this.bookable = booking.bookable;
			this.openModalEditBooking = true;
			this.template = template;
		},
		slotsLeft(event) {
			return event.booking.capacity == null ? '∞' : event.booking.capacity - event.booking.participants.length;
		},
		slotsLeftRemaining(event) {
			return (event.booking.capacity == null) || (event.booking.capacity - event.booking.participants.length > 0) ? 'badge-success' : 'badge-danger';
		},
		onEventEdited(booking) {
			this.getBookings().then((response) => {
				this.zoomToEvent(booking);
			});
		},
		onBookingTemplateSaved(bookingTemplate) {
			this.bookingTemplates.push(bookingTemplate);
			this.getBookings();
		},
		onBookingTemplateDeleted(id) {
			const deletePos = this.bookingTemplates.findIndex(item => item.id === id);
			if (deletePos > -1) {
				this.bookingTemplates.splice(deletePos, 1);
			}
		},
		onModalAbort() {
			this.booking = {};
			this.getBookings();
		},
		zoomToEvent(booking) {
			this.$refs.vuecal.switchView(this.$refs.vuecal.view.id, new Date(booking.starts_at));
		},
		onEventDragStart (e, draggable) {
			e.dataTransfer.setData('event', JSON.stringify(draggable))
			e.dataTransfer.setData('cursor-grab-at', e.offsetY)
		},
		checkEditableEvents() {
			this.getDimensions();
			return this.isMobileDevice() ? {} : { title: false, drag: true, resize: true, delete: false, create: this.canCreate() };
		},
		onEventDrop(event) {
			if (event.external) {
				var booking = event.event.body.booking;
				var duration = Math.abs(moment(booking.starts_at,).diff(booking.ends_at, 'minutes'));

				var payload = {
					tag: booking.booking_type.tag,
					bookable_id: booking.booking_type?.bookable?.id,
					category_id: booking.booking_category?.id,
					is_bookable: booking.is_bookable,
					label: booking.label,
					description: booking.description,
					capacity: booking.capacity,
					starts_at: moment(event.event.start).format('YYYY-MM-DD HH:mm:ss'),
					ends_at: moment(event.event.start).add(duration, 'minutes') .format('YYYY-MM-DD HH:mm:ss'),
				};

				BojAPI.createBooking(payload).then((response) => {
					this.showConfirmation(this.$t('labels.booking-created'));
					this.getBookings();
				}).catch((error) => {}); 

			} else {
				if (event.event.booking) {
					BojAPI.updateBookingTime(event.event.booking.id, {
						starts_at: event.event.start.toLocaleString(),
						ends_at: event.event.end.toLocaleString(),
					})
						.then((response) => {
							this.$notify({
								group: 'foo',
								text: 'Bokning uppdaterad',
								type: 'success',
								duration: 3000,
							});
						})
						.catch((error) => {});
				}
			}
			
		},
		onEventCreate(event, deleteEventFunction) {
			this.deleteEventFunction = deleteEventFunction;
			if (!this.canCreate()) {
				return false;
			}

			return event;
		},
		onEventDragCreate(event) {
			if (!this.canCreate()) {
				return false;
			}
			this.booking = {};
			this.booking.starts_at = moment(event.start).format('YYYY-MM-DD HH:mm:ss');
			this.booking.ends_at = moment(event.end).format('YYYY-MM-DD HH:mm:ss');
			this.booking.is_bookable = true;
			this.template = {
				id: null,
				label: null,
			}
			this.openModalEditBooking = true;
		},
		onEventChange(event) {
		},
		onEventDurationChange(event) {
			if (event.event.booking) {
				BojAPI.updateBookingTime(event.event.booking.id, {
					ends_at: event.event.end.toLocaleString(),
				}).then((response) => {
						this.$notify({
							group: 'foo',
							text: this.$t('labels.booking-updated'),
							type: 'success',
							duration: 3000,
						});
					})
					.catch((error) => {});
			}
		},
		canCreate() {
			return this.bookables.length > 0 && this.$store.getters.permissions.some(r=> ['calendar-office-create', 'calendar-witness-support-create', 'calendar-national-create', 'calendar-interview-create'].includes(r));
		},
		canResizable(booking) {
			return booking.creator.id == this.user.user.id;
		},
		canDraggable(booking) {
			return booking.creator.id == this.user.user.id;
		},
		getBookables() {
			BojAPI.getBookables().then((response) => {
				this.bookables = response.data;
			});
		},
		getFilters() {
			return BojAPI.getBookingFilters().then((response) => {
		
				this.filters =  [
					...[{
						label: "Allt",
						label_en: "All"
					}],
					...response.data
				];

				this.bookingTypeId = this.filters[0];
			});
			

			
		},
		async getBookings(event = null) {
			if(!event) {
				event = {
					...{view: this.$refs.vuecal.activeView},
					...this.$refs.vuecal.view
				}
			}
			this.bookings = [];

			var startsAt;
			var endsAt;
			switch (event.view) {
				case 'month':
					startsAt = event.firstCellDate;
					endsAt = event.lastCellDate;
					break;
				case 'year':
				case 'week':
				case 'day':
					startsAt = event.startDate;
					endsAt = event.endDate;
                break;
            }

			if(event.view == 'year') {
				BojAPI.groupAvailableBookings({ startsAt: startsAt, endsAt: endsAt, bookingTypeId: this.bookingTypeId.id, bookableId: this.bookableId.id}).then((response) => {
					let self = this;
					this.bookings = response.data.map(function (booking) {
						return self.applyBookingFields(booking);
					});
				
				});
			} else {
				BojAPI.getAvailableBookings({ startsAt: startsAt, endsAt: endsAt, bookingTypeId: this.bookingTypeId.id, bookableId: this.bookableId.id}).then((response) => {
					let self = this;
					this.bookings = response.data.map(function (booking) {
						return self.applyBookingFields(booking);
					});
				
				});
			}
		

		},
		applyBookingFields(booking) {
			return {
					start: booking.starts_at,
					end: booking.ends_at,
					title: booking.label,
					content: '<i class="' + booking.booking_type.icon + '" style="color: ' + booking.booking_type.icon_color + ' !important;"></i>',
					booking: booking,
					resizable: this.canResizable(booking),
					draggable: this.canDraggable(booking),
					deletable: true,
			}
		},
		onEventClick(event, e) {
			if(event.booking) {
				this.event = event;
				this.openModalShowBooking = true;
				this.$router.push({
					name: 'calendar',
					query: { id: event.booking.id },
				}).catch(()=>{});
			}
			
			// Prevent navigating to narrower view (default vue-cal behavior).
			e.stopPropagation();
		},
		showConfirmation(value) {
			this.$notify({
				group: 'foo',
				text: value,
				type: 'success',
				duration: 3000,
			});
		},
		showError(value) {
			this.$notify({
				group: 'foo',
				text: value,
				type: 'warning',
				duration: 3000,
			});
		},
		activeView() {
			return this.isMobileDevice() ? 'month' : 'week';
		},
		disableViews() {
			return this.isMobileDevice() ? ['years','week'] : ['years'];
		},
		isMobileDevice() {
			return this.screenWidth < 768;
		},
		getDimensions() {
			this.screenWidth = window.innerWidth;
		}
	},
};
</script>
<style scoped>
.booking-templates {
	overflow-x: auto;
	overflow-y: hidden;
}

.calender >>> .event-content {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	box-sizing: border-box;
}
.calender >>> .vuecal__event-title {
	font-weight: 400;
	font-size: 1.0em;
	font-weight: bold;
}

.calender >>> .vuecal--month-view .vuecal__cell {height: 80px;}

.calender >>> .vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.calender >>> .vuecal--month-view .vuecal__cell-date {padding: 4px;}
.calender >>> .vuecal--month-view .vuecal__no-event {display: none;}

.calender >>> .badge {
	font-weight: 400;
	font-size: 1.0em;
	font-weight: bold;
}

.calender >>> .vuecal__event {
	cursor: pointer;
}
.calender >>> .vuecal--year-view .vuecal__cell-date {padding: 6px;}
.calender >>> .vuecal--year-view .vuecal__no-event {display: none;}
.calender >>> .year-view .vuecal__cell--has-events {background-color: #89e481;}
.calender >>> .year-view .vuecal__cell-events-count {display: none;}

.calender >>> .month-view .vuecal__cell--has-events {background-color: #89e481;}
.calender >>> .month-view .vuecal__cell-events-count {display: none;}

.calender >>> .participants ul {
	margin: 0;
	padding: 0;
}

.calender >>> .participants ul li:before {
	content: "\2713";
	margin: 0 5px 0 0px;
    padding: 0 0 0 0px;
}
</style>
