<template>
  <modal name="modal-show-booking" :adaptive="true" @closed="closeModal" :width=1150 height="auto" :scrollable="true" >
    <div class="clearfix float-right modal-header border-0">
        <button class="close" @click="$modal.hide('modal-show-booking');">
          <span aria-hidden="true">×</span>
      </button>
		</div>
    <div class="m-4">
      <div class="row">
        <div class="col-4 text-left">
          <h4 class="pl-0">{{ event.booking.label }} <i :class="event.booking.booking_category ? event.booking.booking_category.icon : event.booking.booking_type.icon" :style="{color: event.booking.booking_category ? event.booking.booking_category.icon_color : event.booking.booking_type.icon_color}"></i></h4> 
        </div>
        <div class="col-4 text-center">
          <h4 v-if="event.booking.bookable">{{ $to(event.booking.bookable,'label') }}</h4>
        </div>
        <div class="col-4 text-right">
          <h4 class="pr-0">{{ $to(event.booking.booking_type, 'label') }}</h4>
        </div>
      </div>
      <div class="px-3 bt-1">
        <div class="row bb-1">
          <div class="col-12 br-1 pt-3">
            <div class="row">
              <div class="col-lg-5 order-2 order-lg-1">
                  <p>
                      <strong>{{ $t('labels.time') }} {{ moment(event.booking.starts_at).format('YYYY-MM-DD HH:mm') }} - {{ moment(event.booking.ends_at).format('YYYY-MM-DD HH:mm') }}</strong>
                  </p>
                  <p v-if="event.booking.capacity > 0">
                      <strong>{{ $t('labels.capacity') }} <span class="badge badge-success">{{ event.booking.capacity }}</span></strong>
                  </p>
                
                  <p style="white-space: pre-line" v-html="makeDescription(event.booking.description)">
                  
                  </p>

                  <p class="text-align: left"><button type="submit" @click="remove" v-if="canRemove()" class="btn btn-danger float-left mt-3 mr-3">{{ $t('labels.delete-event') }}</button></p>
                  <p class="text-align: left"><button type="submit" @click="showEditModal" v-if="canShowEditModal()" class="btn btn-prio-normal float-left mt-3 mr-3">{{ $t('labels.change-event') }}</button></p>
              </div>
              <div class="col-lg-2 order-1 py-3 py-lg-0 order-lg-3 text-lg-right" v-if="event.booking.is_bookable" >
                  <button type="submit" @click="participate" v-if="!isParticipating() && (event.booking.capacity == null || event.booking.capacity > event.booking.participants.length)" class="btn btn-success mb-3 mt-lg-0 mr-3">{{ $t('labels.book-me-in') }}</button>
                  <button type="submit" @click="cancel(event.booking.id, event.booking.participants.find((item) => item.model.id === user.user.id).id)" v-if="isParticipating() && canUnbook()" class="btn btn-danger mb-3 mt-lg-0 mr-3">{{ $t('labels.unbook-me') }}</button>
              </div>
              <div class="col-lg-5 order-3 py-3 py-lg-0 order-lg-2">
                  <div v-if="event.booking.is_bookable && event.booking.participants.length > 0">
                    <p v-if="canSeeEmails() && event.booking.participants.length > 0">
                        <a class="h-100 btn btn-light" :href="'mailto:' + multipleEmails()"><i class="mr-2 far fa-envelope"></i> {{ $t('labels.mail-x-participants', {number: event.booking.participants.length }) }}</a>
                    </p> 
                    <p v-if="!canSeeEmails() || event.booking.participants.length == 0">
                        <strong>{{ event.booking.participants.length }}  {{ $t('labels.participant') }}</strong>
                    </p> 
                    <div class="pt-3">
                      <table class="table table-sm table-borderless">
                        <thead>
                        <tr>
                          <th width="20%" style="padding-left: 5px;">{{ $t('labels.participant') }}</th>
                          <th style="" width="13%" v-if="canSeeEmails()"></th>
                          <th width="13%" v-if="canUnbookOthers()"></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr v-for="participant in event.booking.participants" :key="participant.id">
                            <td style="padding-left: 5px;">{{ participantName(participant) }}</td>
                            <td style="text-align: center" v-if="canSeeEmails()"><a class="" :href="'mailto:' + participantEmail(participant.model)"><i class=" far fa-envelope"></i></a></td>
                            <td style="text-align: right">
                              <a role="button" class="btn btn-outline-danger float-right btn-sm" style="margin-top: -4px;" v-if="canUnbookOthers()" @click="cancel(event.booking.id, participant.id)"><i class="fas fa-trash"></i> {{ $t('labels.unbook') }}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';

import moment from 'moment';

export default {
  props: {
    event: Object,
    member: Object,
  },
  components: {
  },
  data() {
    return {
      moment: moment,
    };
  },
  created() {
  },
  mounted() {
    this.$modal.show('modal-show-booking');
  },
  computed: {
    user() {
			return this.$store.getters.user;
		},
  },
  methods: {
    makeDescription(description) {
       return description;
    },
    isParticipating() {
        return this.event.booking.participants.filter((item) => item.model.id === this.user.user.id).length;
    },
    participantEmail(participant) {
      if (participant.email) {
        return participant.email;
      } else if (participant.member) {
        return participant.member.email;
      }

      return '';
    },
    canSeeEmails() {
        return ((
            (this.event.booking.booking_type.tag == 'national' && this.$store.getters.permissions.includes('calendar-national-can-see-emails')) ||
            (this.event.booking.booking_type.tag == 'witness_support' && this.$store.getters.permissions.includes('calendar-witness-support-can-see-emails')) ||
            (this.event.booking.booking_type.tag == 'office' && this.$store.getters.permissions.includes('calendar-office-can-see-emails')) ||
            (this.event.booking.booking_type.tag == 'interview' && this.$store.getters.permissions.includes('calendar-interview-can-see-emails'))
          ))
          ;
    },
    canRemove() {
        return (this.isCreator() && (
            (this.event.booking.booking_type.tag == 'national' && this.$store.getters.permissions.includes('calendar-national-delete-own')) ||
            (this.event.booking.booking_type.tag == 'witness_support' && this.$store.getters.permissions.includes('calendar-witness-support-delete-own')) ||
            (this.event.booking.booking_type.tag == 'office' && this.$store.getters.permissions.includes('calendar-office-delete-own')) ||
            (this.event.booking.booking_type.tag == 'interview' && this.$store.getters.permissions.includes('calendar-interview-delete-own'))
          )) || (!this.isCreator() && (this.event.booking.booking_type.tag == 'national' && this.$store.getters.permissions.includes('calendar-national-delete-others')) ||
            (this.event.booking.booking_type.tag == 'witness_support' && this.$store.getters.permissions.includes('calendar-witness-support-delete-others')) ||
            (this.event.booking.booking_type.tag == 'interview' && this.$store.getters.permissions.includes('calendar-interview-delete-others')) ||
            (this.event.booking.booking_type.tag == 'office' && this.$store.getters.permissions.includes('calendar-office-delete-others')))
          ;
    },
    canUnbook() {
        return ((
            (this.event.booking.booking_type.tag == 'national' && this.$store.getters.permissions.includes('calendar-national-unbook-own')) ||
            (this.event.booking.booking_type.tag == 'witness_support' && this.$store.getters.permissions.includes('calendar-witness-support-unbook-own')) ||
            (this.event.booking.booking_type.tag == 'office' && this.$store.getters.permissions.includes('calendar-office-unbook-own')) ||
            (this.event.booking.booking_type.tag == 'interview' && this.$store.getters.permissions.includes('calendar-interview-unbook-own'))
          ))
          ;
    },
    canUnbookOthers() {
        return (this.event.booking.booking_type.tag == 'national' && this.$store.getters.permissions.includes('calendar-national-unbook-others')) ||
            (this.event.booking.booking_type.tag == 'witness_support' && this.$store.getters.permissions.includes('calendar-witness-support-unbook-others')) ||
            (this.event.booking.booking_type.tag == 'office' && this.$store.getters.permissions.includes('calendar-office-unbook-others')) ||
            (this.event.booking.booking_type.tag == 'interview' && this.$store.getters.permissions.includes('calendar-interview-unbook-others'))
          ;
    },
    isCreator() {
      return this.event.booking.creator.id == this.user.user.id;
    },
    canShowEditModal() {
      return (this.isCreator() && (
            (this.event.booking.booking_type.tag == 'national' && this.$store.getters.permissions.includes('calendar-national-edit-own')) ||
            (this.event.booking.booking_type.tag == 'interview' && this.$store.getters.permissions.includes('calendar-interview-edit-own')) ||
            (this.event.booking.booking_type.tag == 'witness_support' && this.$store.getters.permissions.includes('calendar-witness-support-edit-own')) ||
            (this.event.booking.booking_type.tag == 'office' && this.$store.getters.permissions.includes('calendar-office-edit-own'))
          )) || (!this.isCreator() && (this.event.booking.booking_type.tag == 'national' && this.$store.getters.permissions.includes('calendar-national-edit-others')) ||
            (this.event.booking.booking_type.tag == 'witness_support' && this.$store.getters.permissions.includes('calendar-witness-support-edit-others')) ||
            (this.event.booking.booking_type.tag == 'interview' && this.$store.getters.permissions.includes('calendar-interview-edit-others')) ||
            (this.event.booking.booking_type.tag == 'office' && this.$store.getters.permissions.includes('calendar-office-edit-others')));
    },
    showEditModal() {
      this.$emit('showEditModal', this.event.booking);
      this.closeModal();
    },
    participate() {
			BojAPI.participateInBooking(this.event.booking.id)
				.then((response) => {
					this.$notify({
						group: 'foo',
						text: this.$t('labels.booked'),
						type: 'success',
						duration: 3000,
					});
                    this.event.booking = response.data;
                    this.$emit('changed');
				})
				.catch((error) => {});
		},
    cancel(bookingId, userId) {
        var diff = moment().diff(this.event.booking.starts_at, 'hours');
        if(diff <= 0 && diff >= -72) {
            this.$confirm({
              title: this.$t('labels.notify-the-person-in-charge'),
              message: this.$t('labels.confirm-unbook-text'),
              button: {
                yes: this.$t('labels.ok'),
              },
              callback: () => {
                  this.cancelBooking(bookingId, userId);
              },
            });
        } else {
          this.cancelBooking(bookingId, userId);
        }
    },
    remove() {
        var retVal = confirm(this.$t('labels.delete-booking-confirmation'));
        if( retVal == true ) {
          BojAPI.deleteBooking(this.event.booking.id)
            .then((response) => {
                this.$notify({
                    group: 'foo',
                    text: this.$t('labels.booking-deleted'),
                    type: 'success',
                    duration: 3000,
                });
                this.$emit('changed');
                this.closeModal();
            })
            .catch((error) => {});
          return true;
        }
    },
    multipleEmails() {
        return this.event.booking.participants.map((participant) => this.participantEmail(participant.model)).join(',');
    },

    cancelBooking(bookingId, userId) {
      BojAPI.cancelBooking(bookingId, userId)
        .then((response) => {
            this.$notify({
                group: 'foo',
                text: this.$t('labels.unbooked'),
                type: 'success',
                duration: 3000,
            });
            this.event.booking = response.data;
            this.$emit('changed');
        })
        .catch((error) => {});
    },
    participantName(participant) {
      return participant.model.ref_id ? '#' + participant.model.ref_id + ' ' + participant.model.name : participant.model.name;
    },
    
    /**
     * Close modal
     */
    closeModal() {
      this.$emit('closed');
    },

    /**
     * Display Error Message
     */
    showError(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },

    /**
     * Display Confirmation Message
     */
    showConfirmation(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },


    


  },
};

</script>
<style scoped> /* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */


select {
    font-family: 'Font Awesome\ 5 Brands' , 'arial'
  }
.fa option {

    font-weight: 900;
}

select.form-control.fa option{ font-weight: 900; }
</style>
