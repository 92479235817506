<template>
  <modal name="save-template-modal" :adaptive="true"  @closed="closeSaveTemplate" :width=1150 height="auto" :scrollable="true"
         :maxHeight="600">
    <div class="clearfix">
      <h4 class="float-left">{{ $t('labels.save-template') }}</h4>
      <button type="submit" class="btn btn-success float-right mt-3 mr-3" @click="saveTemplate">{{ $t('labels.save') }}</button>
      <button type="submit" class="btn btn-outline-danger float-right mt-3 mr-3" @click="closeSaveTemplate">{{ $t('labels.cancel') }}</button>
    </div>
    <div class="px-3 bt-1 pt-3">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="subject">{{ $t('labels.name') }}</label>
            <input type="text" v-model="label" class="form-control" id="subject" aria-describedby="emailHelp"
                   placeholder=""/>
          </div>
          <div class="form-check">

          
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';

export default {
  data() {
    return {
      label: '',
    };
  },
  mounted() {
    this.$modal.show('save-template-modal');
  },
  methods: {
    closeSaveTemplate() {
      this.$emit('closed');
    },
    saveTemplate() {
      if (this.label.length === 0) {
        alert(this.$t('labels.need-to-enter-name'));
        return false;
      }
      this.$modal.hide('save-template-modal');
      this.$emit('closed');
      this.$emit('templateSaved', this.label);
    },
  },
};
</script>
