<template>
  <modal name="modal-show-booking" :adaptive="true" @closed="closeModal" :width=1150 height="auto" :scrollable="true" >
    <div class="clearfix float-right modal-header border-0">
        <button class="close" @click="$modal.hide('modal-show-booking');">
          <span aria-hidden="true">×</span>
      </button>
		</div>
    <div class="m-4">
      <div class="row">
        <div class="col-4 text-left">
          <h4 class="pl-0">{{ event.booking.label }} <i :class="event.booking.booking_category ? event.booking.booking_category.icon : event.booking.booking_type.icon" :style="{color: event.booking.booking_category ? event.booking.booking_category.icon_color : event.booking.booking_type.icon_color}"></i></h4> 
        </div>
        <div class="col-4 text-center">
          <h4 v-if="event.booking.bookable">{{ $to(event.booking.bookable,'label') }}</h4>
        </div>
        <div class="col-4 text-right">
          <h4 class="pr-0">{{ $to(event.booking.booking_type, 'label') }}</h4>
        </div>
      </div>
      <div class="px-3 bt-1">
        <div class="row bb-1">
          <div class="col-12 br-1 pt-3">
            <div class="row">
              <div class="col-lg-5 order-2 order-lg-1">
                  <p>
                      <strong>{{ $t('labels.time') }} {{ moment(event.booking.starts_at).format('YYYY-MM-DD hh:mm') }} - {{ moment(event.booking.ends_at).format('YYYY-MM-DD hh:mm') }}</strong>
                  </p>
                  <p v-if="event.booking.capacity > 0">
                      <strong>{{ $t('labels.capacity') }} <span class="badge badge-success">{{ event.booking.capacity }}</span></strong>
                  </p>
                
                  <p style="white-space: pre-line" v-html="makeDescription(event.booking.description)">
                  
                  </p>

                  <p class="text-align: left"></p>
                
              </div>
              <div class="col-lg-2 order-1 py-3 py-lg-0 order-lg-3 text-lg-right" v-if="event.booking.is_bookable" >
                  <button :disabled="participateLock" type="submit" @click="participate" v-if="!isParticipating() && (event.booking.capacity == null || event.booking.capacity > event.booking.participants.length)" class="btn btn-success mb-3 mt-lg-0 mr-3">{{ $t('labels.book-me-in') }}</button>
                  <button :disabled="participateLock" type="submit" @click="cancel(event.booking.id)" v-if="isParticipating()" class="btn btn-danger mb-3 mt-lg-0 mr-3">{{ $t('labels.unbook-me') }}</button>
              </div>
              <div class="col-lg-5 order-3 py-3 py-lg-0 order-lg-2">
                  <div v-if="event.booking.is_bookable && event.booking.participants.length > 0">
                    <p v-if="canSeeEmails() && event.booking.participants.length > 0">
                        <a class="h-100 btn btn-light" :href="'mailto:' + multipleEmails()"><i class="mr-2 far fa-envelope"></i> {{ $t('labels.mail-x-participants', {number: event.booking.participants.length }) }}</a>
                    </p> 
                    <p v-if="!canSeeEmails() || event.booking.participants.length == 0">
                        <strong>{{ event.booking.participants.length }}  {{ $t('labels.participant') }}</strong>
                    </p> 
                    <div class="pt-3">
                      <table class="table table-sm table-borderless">
                        <thead>
                        <tr>
                          <th width="20%" style="padding-left: 5px;">{{ $t('labels.participant') }}</th>
                          <th style="" width="13%" v-if="canSeeEmails()"></th>
                          <th width="13%"></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr v-for="participant in event.booking.participants" :key="participant.id">
                            <td style="padding-left: 5px;">{{ participant.model.name }}</td>
                            <td style="text-align: center" v-if="canSeeEmails()"><a class="" :href="'mailto:' + participantEmail(participant.model)"><i class=" far fa-envelope"></i></a></td>
                            <td style="text-align: right">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
              
            </div>
            <div class="row">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-primary align-self-end mr-3 mb-3" @click="closeModal()">
                  {{ $t('labels.close') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';

import moment from 'moment';

export default {
  props: {
    event: Object,
  },
  components: {
  },
  data() {
    return {
      moment: moment,
      participateLock: false,
    };
  },
  created() {
  },
  mounted() {
    this.$modal.show('modal-show-booking');
  },
  computed: {
    invitedUser() {
      return this.$store.getters.invitedUser;
    },
  },
  methods: {
    makeDescription(description) {
       return description;
    },
    isParticipating() {
        return this.event.booking.participants.filter((item) => item.model.id === this.invitedUser.id).length;
    },
    participantEmail(participant) {
      return participant.member ? participant.member.email : '';
    },
    canSeeEmails() {
        return false;
    },
    participate() {
      this.participateLock = true;
			BojAPI.participateBookingInvite(this.event.booking.id)
				.then((response) => {
          this.participateLock = false;
					this.$notify({
						group: 'foo',
						text: this.$t('labels.booked'),
						type: 'success',
						duration: 3000,
					});
                    this.event.booking = response.data;
                    this.$emit('changed');
				})
				.catch((error) => {
          this.participateLock = false;
          this.$confirm({
              title: this.$t('labels.unknown-error'),
              message: this.$t('labels.error-calendar-invite-already-participating'),
              button: {
                yes: this.$t('labels.ok'),
              },
          });
        });
		},
    cancel(bookingId) {
        this.cancelBooking(bookingId);
    },
    multipleEmails() {
        return this.event.booking.participants.map((participant) => participant.model.member && participant.model.member.email).join(',');
    },

    cancelBooking(bookingId) {
      this.participateLock = true;
      BojAPI.cancelBookingInvite(bookingId)
        .then((response) => {
            this.participateLock = false;
            this.$notify({
                group: 'foo',
                text: this.$t('labels.unbooked'),
                type: 'success',
                duration: 3000,
            });
            this.event.booking = response.data;
            this.$emit('changed');
        })
        .catch((error) => {
          this.participateLock = false;
        });
    },
    
    /**
     * Close modal
     */
    closeModal() {
      this.$emit('closed');
    },

    /**
     * Display Error Message
     */
    showError(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },

    /**
     * Display Confirmation Message
     */
    showConfirmation(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },
  },
};

</script>
<style scoped> /* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */


select {
    font-family: 'Font Awesome\ 5 Brands' , 'arial'
  }
.fa option {

    font-weight: 900;
}

select.form-control.fa option{ font-weight: 900; }
</style>
