<template>
	<layout-invite>
		<div class="home">
			<modal-show-booking
				:event="event"
				@closed="
					openModalShowBooking = null;
					$router.push({
						name: 'invite.calendar',
						query: {},
					});
				"
				@changed="getBookings()"
				v-if="openModalShowBooking"
			></modal-show-booking>

			<div class="subheader">
				<div class="subheader-main">
					<div class="subheader-left">
				
					</div>
				
					<div class="subheader-right">
						
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
					<div class="widget progress-list">
						<div class="widget-header row ">
							<div class="widget-header-label mt-3 mt-lg-0 order-1 order-lg-1 col-11 col-lg-2">
								
								<h3>
									{{ $t('labels.calendar') }}
								</h3>
							
								<span style="min-width: 140px;" class="mx-3">
									
								</span>
							</div>
							<div class="widget-header-label mb-3 mb-lg-0 order-2 order-lg-2 col-11 col-lg-3">

							</div>
							<div class="d-flex align-items-center order-2 order-lg-3 col-1 col-lg-1">
								<div class=" ml-auto d-none d-lg-block">
									<a class="cursor-pointer text-uppercase" @click="selectedDate = new Date()">{{ $t('labels.today') }}</a> 
								</div>
							</div>
						</div>
						<div class="widget-body no-padding calender">
							<vue-cal
								ref="vuecal"
								:dblclickToNavigate="false"
								:clickToNavigate="true"
								:selected-date="selectedDate"
								:on-event-click="onEventClick"
								:snap-to-time="15"
								@event-change="onEventChange"
								:locale="locale()"
								:active-view=activeView()
								:disable-views=disableViews()
								events-count-on-year-view
								events-count-on-month-view
								:events="bookings"
								:time-from="6 * 60"
								:time-to="24 * 60"
						
								@view-change="getBookings"
							>
								<template v-slot:event="{ event }"> 
									<div v-b-popover.hover.html.right="hoverParticipantList(event)" :title="hoverTitle(event)" v-if="event.booking && event.booking.booking_type" class="event-content" :style="{ backgroundColor: event.booking.booking_type.background_color }">
										<div class="vuecal__event-title">
										<span class="icon"><i :class="event.booking.booking_category ? event.booking.booking_category.icon : event.booking.booking_type.icon" :style="{ color: event.booking.booking_category ? event.booking.booking_category.icon_color : event.booking.booking_type.icon_color }"></i></span>	{{ event.title }} <span v-if="event.booking.is_bookable" class="badge" :class="
												slotsLeftRemaining(event)
												
											"> {{ slotsLeft(event) }} </span>
										</div>
										<small class="vuecal__event-time">
											<span>{{ event.start.formatTime() }}</span> - <span>{{ event.end.formatTime() }}</span>
										</small>
										<div class="participants" v-if="event.booking.is_bookable && event.booking.participants.length > 0">
											<ul class="text-center list-unstyled" v-for="participant in event.booking.participants" :value="participant.id" :key="participant.id">
												<li class="text-center"><small><b>{{ participant.model.name }}</b></small></li>
											</ul>
										</div>
									</div>
								</template>
							</vue-cal>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-invite>
</template>

<script>
import LayoutInvite from '@/views/Layouts/Invite';

import ModalShowBooking from '@/components/calendar/invite/ModalShowBooking';
import BojAPI from '@/api/boj';
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/sv.js';
import 'vue-cal/dist/drag-and-drop.js';
import 'vue-cal/dist/vuecal.css';
import _ from 'lodash';
import moment from 'moment';
import { i18n } from '@/plugins/i18n';
import DatePicker from '@/components/DatePicker';
import Cookies from '@/service/cookie';

export default {
	name: 'home',
	components: {
		LayoutInvite,
		ModalShowBooking,
		VueCal,
		DatePicker,
		i18n
	},

	data() {
		return {
			screenWidth: 0,
			moment: moment,
			bookings: [],
			event: null,
			bookable: {},
			bookableType: {},
			booking: {},
			isLoading: false,
			openModalEditBooking: null,
			openModalShowBooking: null,
			selectedDate: null,
			bookingTypeId: {},
			bookableId: {},
			countYears: [],
		};
	},
	computed: {
		user() {
			return this.$store.getters.user;
		},
		attributes() {
			return [{}];
		},
	},
	mounted() {
		if(this.$route.query.uuid) {
			Cookies.set('invite-token', this.$route.query.uuid);
		}

		window.addEventListener('resize', this.getDimensions);
		this.getDimensions();

		this.countTotalBookingsAvailable().then((total) => {
			if(total == 0) {
				this.$confirm({
					title: this.$t('labels.information'),
					message: this.$t('labels.error-calendar-invite-no-interview-times-available'),
					button: {
						yes: this.$t('labels.ok'),
					},
				});
			}
		});
	

		this.getBookings().then(() => {
			if ('id' in this.$route.query) { 
				BojAPI.getInvitedBooking(this.$route.query.id).then((response) => {
					var booking = response.data;
					if (booking) {
						this.zoomToEvent(booking);
						this.event = {};
						this.event.booking = booking;
						this.openModalShowBooking = true;
					}
				});
			}
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.getDimensions);
	},

	methods: {
		hoverTitle(event) {
			if(event.booking.is_bookable && event.booking.participants.length > 0) {
				return this.$t('labels.participant');
			}
			return '';
		},
		hoverParticipantList(event) {
			if(event.booking.is_bookable && event.booking.participants.length > 0) {
				return event.booking.participants.map((participant) => participant.model.name).join("<br/>");
			}
			return '';
		},
		locale() {
			return i18n.locale;
		},
		slotsLeft(event) {
			return event.booking.capacity == null ? '∞' : event.booking.capacity - event.booking.participants.length;
		},
		slotsLeftRemaining(event) {
			return (event.booking.capacity == null) || (event.booking.capacity - event.booking.participants.length > 0) ? 'badge-success' : 'badge-dark';
		},
		onModalAbort() {
			this.booking = {};
			this.getBookings();
		},
		zoomToEvent(booking) {
			this.$refs.vuecal.switchView(this.$refs.vuecal.view.id, new Date(booking.starts_at));
		},
		onEventChange(event) {
		},

		async countTotalBookingsAvailable() {
			return BojAPI.countTotalBookingsAvailable({ bookingTypeId: this.bookingTypeId.id, bookableId: this.bookableId.id }).then((response) => {
				return response.data;
			});
		},

		async getBookings(event = null) {
			if(!event) {
				event = {
					...{view: this.$refs.vuecal.activeView},
					...this.$refs.vuecal.view
				}
			}
			this.bookings = [];

			var startsAt;
			var endsAt;
			switch (event.view) {
				case 'month':
					startsAt = event.firstCellDate;
					endsAt = event.lastCellDate;
					break;
				case 'year':
				case 'week':
				case 'day':
					startsAt = event.startDate;
					endsAt = event.endDate;
                break;
            }

			if(event.view == 'year') {
				BojAPI.getInvitedGroupAvailableBookings({ startsAt: startsAt, endsAt: endsAt, bookingTypeId: this.bookingTypeId.id, bookableId: this.bookableId.id}).then((response) => {
					let self = this;
					this.bookings = response.data.map(function (booking) {
						return self.applyBookingFields(booking);
					});
				
				});
			} else {
				BojAPI.getInvitedAvailableBookings({ startsAt: startsAt, endsAt: endsAt, bookingTypeId: this.bookingTypeId.id, bookableId: this.bookableId.id}).then((response) => {
					let self = this;
					this.bookings = response.data.map(function (booking) {
						return self.applyBookingFields(booking);
					});
				
				});
			}
		

		},
		applyBookingFields(booking) {
			return {
					start: booking.starts_at,
					end: booking.ends_at,
					title: booking.label,
					content: '<i class="' + booking.booking_type.icon + '" style="color: ' + booking.booking_type.icon_color + ' !important;"></i>',
					booking: booking,
					resizable: false,
					draggable: false,
					deletable: true,
			}
		},
		onEventClick(event, e) {
			if(event.booking) {
				this.event = event;
				this.openModalShowBooking = true;
				this.$router.push({
					name: 'invite.calendar',
					query: { id: event.booking.id },
				}).catch(()=>{});
			}
			
			// Prevent navigating to narrower view (default vue-cal behavior).
			e.stopPropagation();
		},
		showConfirmation(value) {
			this.$notify({
				group: 'foo',
				text: value,
				type: 'success',
				duration: 3000,
			});
		},
		showError(value) {
			this.$notify({
				group: 'foo',
				text: value,
				type: 'warning',
				duration: 3000,
			});
		},
		activeView() {
			return this.isMobileDevice() ? 'month' : 'week';
		},
		disableViews() {
			return this.isMobileDevice() ? ['years','week'] : ['years'];
		},
		isMobileDevice() {
			return this.screenWidth < 768;
		},
		getDimensions() {
			this.screenWidth = window.innerWidth;
		}
	},
};
</script>
<style scoped>
.booking-templates {
	overflow-x: auto;
	overflow-y: hidden;
}

.calender >>> .event-content {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	box-sizing: border-box;
}
.calender >>> .vuecal__event-title {
	font-weight: 400;
	font-size: 1.0em;
	font-weight: bold;
}

.calender >>> .vuecal--month-view .vuecal__cell {height: 80px;}

.calender >>> .vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.calender >>> .vuecal--month-view .vuecal__cell-date {padding: 4px;}
.calender >>> .vuecal--month-view .vuecal__no-event {display: none;}

.calender >>> .badge {
	font-weight: 400;
	font-size: 1.0em;
	font-weight: bold;
}

.calender >>> .vuecal__event {
	cursor: pointer;
}
.calender >>> .vuecal--year-view .vuecal__cell-date {padding: 6px;}
.calender >>> .vuecal--year-view .vuecal__no-event {display: none;}
.calender >>> .year-view .vuecal__cell--has-events {background-color: #89e481;}
.calender >>> .year-view .vuecal__cell-events-count {display: none;}

.calender >>> .month-view .vuecal__cell--has-events {background-color: #89e481;}
.calender >>> .month-view .vuecal__cell-events-count {display: none;}

.calender >>> .participants ul {
	margin: 0;
	padding: 0;
}

.calender >>> .participants ul li:before {
	content: "\2713";
	margin: 0 5px 0 0px;
    padding: 0 0 0 0px;
}
</style>
