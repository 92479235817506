<template>
  <div>
    <div class="header">
      <div class="container header-top">
        <div class="logo"><img src="/assets/logo/logotyp_boj_brottsofferjouren_tel-1.png" height="45" /></div>
        <div class="col-auto text-light">{{ invitedUser.name }}</div>
     
      </div>

      <div class="container header-bottom">
        <div class="row h-100">
          <div class="col h-100">
            <div class="menu-wrapper"></div>
          </div>
          <div class="col-3">
            <div class="header-toolbar flex-row-reverse">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container content">
      <slot />
    </div>

    <footer>
      <div class="container">
        <div class="row pt-5">
          <div class="col-2 offset-5 pt-5">
            <div class="logo text-center"></div>
          </div>
        </div>
      </div>
    </footer>

    <notifications group="foo" />
  </div>
</template>

<style lang="scss"></style>
<script>


import Cookies from '@/service/cookie';

export default {
  name: 'public',
  data() {
     return {
    }; 
  },
  mounted() {    
    if (this.$route.query.uuid) {
      Cookies.set('invite-token', this.$route.query.uuid);
      this.$store.dispatch('getInvitedUser').then((resp) => {
        if(!this.invitedUser.model) {
          this.$confirm({
            title: this.$t('labels.information'),
            message: this.$t('labels.error-calendar-invite-case-closed', {refId: this.invitedUser.ref_id}),
            button: {
              yes: this.$t('labels.ok'),
            },
          });
        }
        
      })
    }
  },
  computed: {
    invitedUser() {
      return this.$store.getters.invitedUser;
    },
  },
  methods: {
  },
  
};
</script>
